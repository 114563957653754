import React, { useState } from 'react';
import MDXSplitter from './MDXSplitter';
import './ShowMore.scss';

const ShowMore = ({children}) => {
  const [contentOpen, setContentOpen] = useState(false);

  const label = contentOpen ? "SHOW LESS" : "SHOW MORE";
  const contentClass = contentOpen ? "content open" : "content";

  return (
    <div id='ShowMore'>
      <div className={contentClass}>
        <MDXSplitter>{children}</MDXSplitter>
      </div>
      <button onClick={() => setContentOpen(!contentOpen)}>{label}</button>
    </div>
  )

}

export default ShowMore