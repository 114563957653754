import React from 'react'
import './HeaderWithImage.scss'

const HeaderWithImage = ({image, text}) => {
  return (
    <div id='HeaderWithImage'>
        <img src={`/assets/${image}`} alt={image} />
        <h3>{text}</h3>
    </div>
  )
}

export default HeaderWithImage